
.App {
  text-align: center;
}

.nav-container {
  display: flex;
  height: 100vh;
  width: 100%;
  max-width: 1366px;
  max-height: 400px;
  margin: auto;
  transform: scale(1);
  flex-direction: row;
  align-items: center; /* remove justify-content: center; */
}
.main-container {
  display: flex;
  height: 30%;
  width: 100%;
  max-width: 1366px;
  max-height: 700px;
  margin: auto;
  margin-top: 10px;
  transform: scale(1);
  flex-direction: column;
  /* overflow: auto; */
}

@media (max-width: 768px) {
  .main-container {
    /* overflow-x: auto; */
    align-content: center;
    
}
}

.chart-container-main {
  display: flex;
  height: 90%;
  width: 100%;
  max-width: 1366px;
  max-height: 700px;
  margin: auto;
  /* transform: scale(1); */
  flex-direction: column;
}
@media (max-width: 768px) {
  .chart-container-main {
    height: 50vh;
    /* transform: scale(1); */
    flex-direction: column;
    align-items: center;
    /* min-height: 700px; */
    /* margin-top: 12rem; */
  }
}

.imported-main-container{
  display: flex;
  height: 100vh;
  width: 100%;
  max-width: 1366px;
  max-height: 700px;
  margin: auto;
  /* margin-top: 40rem; */
  transform: scale(1);
  flex-direction: column;
  align-items: center;
}
@media (max-width: 768px) {
  .imported-main-container {
    flex-direction: column;
    align-items: center;
    min-height: 700px;
    /* margin-top: 30rem; */
  }
}
/* Add this CSS rule to your stylesheets */
.MuiPickersCalendarHeader-switchViewButton {
  padding:2px/* Adjust the margin to move it to the left */
}




.imported-main-container-chart{
  display: flex;
  /* height: 100vh; */
  width: 100%;
  max-width: 1366px;
  max-height: 700px;
  margin: auto;
  /* margin-top: 10rem; */
  transform: scale(1);
  flex-direction: column;
  align-items: center;
}
@media (max-width: 768px) {
  .imported-main-container-chart {
    flex-direction: column;
    align-items: center;
    min-height: 700px;
    /* margin-top: 30rem; */
  }
}

.title-main-container h1 {
  margin: 0;
  text-align: center;
}



.button-title-main-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 50px;
}

.multiple-button-container {
    display: flex;
    align-items: center;
    gap: 20px;
    max-width: 50px;
    margin: 0 auto;
}

.container-heading {
  margin: 0;
  padding: 0;
}




/*Input field */

.filters-container {
  width: 100%;
  /* padding: 1rem; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

}
/* Media query for smaller screens */
@media (max-width: 768px) {
  .filters-container {
    flex-direction: column;
    align-items: flex-start;
  }
}

.small-filters-container {
  width: 100%;
  padding: 0rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

}
/* Media query for smaller screens */
@media (max-width: 768px) {
  .small-filters-container {
    flex-direction: column;
    align-items: center;
    /* margin-bottom: 1rem; */
  }
}

.large-filters-container {
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

}
/* Media query for smaller screens */
@media (max-width: 768px) {
  .large-filters-container {
    flex-direction: row;
    align-items: center;
    /* margin-bottom: 1rem; */
  }
}


.form-container {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  flex: 0 0 35%; /* Make the container take up 35% of the width */
  justify-content: flex-start; /* Align content to the left */

}

.form-container * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

.title-and-form-chart-outer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 65%;
  flex: 0 0 65%; /* Make the container take up 65% of the width */
  margin-left: 10px;
  justify-content: flex-end; /* Align content to the right */
}

.title-and-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 10%;
  margin-top: 0.2rem;
  /* border-right: 1px solid #ddd; */

}
@media (max-width: 768px) {
  .title-and-form-container {
    border-right: none;

  }
}

.title-and-form-useradjust-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Center content horizontally */
  width: 100%;
  flex-wrap: wrap; /* Allow content to wrap to the next line */
  gap: 50px;
  margin: 0 auto;
}


@media (max-width: 768px) {
  .title-and-form-useradjust-container {
    margin-top: 0rem;

  }
}

.title-and-form-initial-container {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-height: 200px;
}
.title-and-form-column-container {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-height: 200px;
}


.title-and-form-chart-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-left: 10px;

}


/*Create a input-button-container */

.input-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 300px;
}

.input-container label {
  flex-basis: 50%;
  text-align: left;
}

.input-container input {
  flex-basis: 50%;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
}

.one-line-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  max-width: 70px;
  padding-bottom: 0px;
  margin-top: 0rem;
}

.button-horizontal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /*max-width: 70px;*/
  padding-bottom: 0px;
  margin-top: 0rem;
}




input,
button {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
  margin: auto;
  width: 100%;

}

input:focus,
button:focus,
button:hover {
  border-color: #8884d8;
}

button {
  background-color: #645e29;  /*#8884d8* */
  color: white;
  cursor: pointer;
}

/*Charts */

.chart-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  max-height: 500px;
  width: 100%;
  overflow: auto;
}
.chart-container-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: center;
  width: 100%;
  overflow: auto;
}


.dashboard-container-scroll {
  overflow:auto;
  /* justify-content: center; */
  /* height: 100%; */
  /* width:100% */
}
/* @media (max-width: 768px) {
  .dashboard-container-scroll {
 
  }
} */

@media (max-width: 768px) {
  .chart-container-bar {
    clear: both;
    min-height: 500px;
    /* flex-direction: column-reverse; */
    margin-top: None;
  }
}
@media (max-width: 768px) {
  .chart-container {
    clear: both;
    min-height: 500px;
    /* flex-direction: column-reverse; */
    margin-top: None;
  }
}

.switch-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.download-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 768px) {
  .download-container {
   margin-top: 1rem;
  }
}

.title-and-chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  /* margin-top: 20px; */
}
@media (max-width: 768px) {
  .title-and-chart {
    display: flex;
    align-items: flex-start;
    /* margin-left: 1rem; */
  }
}

.chart-title {
  /* display: flex; */
  margin-bottom: 10px;
  text-align: center;
}
@media (max-width: 768px) {
  .chart-title {
    /* display: flex; */
    /* align-items: flex-start; */
    text-align: left;
    /* margin-left: 1rem; */
  }
}

.chart-content {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  width: 100%;
}
@media (max-width: 768px) {
  .chart-content {
    width: 100%;
    overflow-x: auto;
   
  }
}

/* new div element to enclose responsive elements */
.centered-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}



/*Table containers */


/*DELETE WHEN WE AGREE TO USE MATERIAL UI TABLE ONLY Create a table-container with width 100% */

.table-title {
  margin-bottom: 10px;
  text-align: center;
  padding-top: 30px;
  border-top: 1px solid #ddd;
  margin-top: 20px;
  justify-content: center;
}

.table-container {
  width: 100%;
  max-width: 1366px;
  height: 100%;
  max-height: 500px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  margin-left: 20px;
}

.row-table-container {
  width: 100%;
  max-width: 1300px;
  height: 100%;
  max-height: 500px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-top: 60px;
  margin-left: 4rem;
  margin-right: 4rem;
}

.row-chart-container {
  width: 100%;
  max-width: 1300px;
  height: 100%;
  max-height: 500px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding-top: 60px;
  margin-left: 4rem;
  margin-right: 4rem;
}
@media (max-width: 768px) {
  .row-chart-container {
    display:flex;
    width: 100%;
    /* align-items: flex-end; */
    justify-content:left
  }
}


.table {
  width: 100%;
  border-collapse: collapse;
}


.table-row,
.table-cell {
  border: 1px solid #ccc;
}

.table-row {
  display: flex;
  border: 1px solid #ccc;
}

.table-cell {
  flex: 1;
  padding: 8px;
  text-align: center;
  border: 1px solid #ccc;
}

/* Dashboard new element styles*/

/* 
.header-data {
  margin:1rem;
  font-weight:700;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: none;
  border:none;
  border-radius:1rem;
  padding:1rem;
} */